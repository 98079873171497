var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[_c('v-card',[_c('v-card-text',[_c('v-row',_vm._l((_vm.cotizadorHotels.ocupation),function(room,indHab){return _c('v-col',{key:indHab,attrs:{"cols":_vm.cotizadorHotels.ocupation.length === 1 ? '12' : (
            (_vm.cotizadorHotels.ocupation.length >= 2 && _vm.cotizadorHotels.ocupation.length <= 4) ? '6' : (
              _vm.cotizadorHotels.ocupation.length > 4 ? '4' : '4'
            )
          )}},[_c('RoomOcupation',{attrs:{"i":indHab + 1,"pos":indHab,"room":room,"min_adult":_vm.configSearch.min_adult_room,"max_adult":_vm.configSearch.max_adult_room,"max_ocupation":_vm.configSearch.max_paxs_room,"max_travels":_vm.configSearch.max_paxs_all,"edad_infants":_vm.configSearch.age_infant,"edad_child":_vm.configSearch.age_child,"min_child":_vm.configSearch.min_child_room,"max_child":_vm.configSearch.max_child_room,"max-age":_vm.maxAge}})],1)}),1),_c('v-row',[_c('v-col',{staticClass:"mt-0 pt-0",attrs:{"cols":_vm.cotizadorHotels.ocupation.length === 1 ? '12' : (
            _vm.cotizadorHotels.ocupation.length === 2 ? '6' : (
              _vm.cotizadorHotels.ocupation.length > 2 ? '4' : '4'
            )
          )}},[_c('v-btn',{staticClass:"font-weight-medium",attrs:{"align":"left","block":"","outlined":"","color":"#8a8d93"},on:{"click":function($event){return _vm.setShowRoomHotelCotizador(!_vm.showOcupationRoomHotel)}}},[_vm._v(" "+_vm._s(_vm.$t('btn.apply'))+" ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }